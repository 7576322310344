// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import "~bootstrap/scss/bootstrap";

@import '~bootstrap-icons/font/bootstrap-icons.css';

@import '~bootstrap-table/dist/bootstrap-table.min.css';


@import '~jbox/dist/jBox.min.css';

@import '~jquery-ui/themes/base/all.css';

$focus: #f90;

.text-focus {color: $focus}
.bg-focus {background-color: $focus}
.border-focus {
	border-color: $focus!important;
}

.searchlink:hover {
	color: $light;
	background-color: $primary;
}

